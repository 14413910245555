<template>
	<div>
		<div class="ser-form">
			<div class="form-item">
				<div class="item-label">系统类型：</div>
				<div class="item-content">
					<RadioGroup v-model="search.type" type="button" button-style="solid">
						<Radio :label="-1">全部</Radio>
						<Radio :label="0">Sass</Radio>
						<Radio :label="1">私有化</Radio>
					</RadioGroup>
				</div>
			</div>
			<div class="form-item">
				<div class="item-label">操作类型：</div>
				<div class="item-content">
					<Button style="margin-right: 10px;" type="primary" icon="md-add" @click="addData">新增版本</Button>
					<Button type="primary" icon="md-search" @click="searchInit">搜索</Button>
				</div>
			</div>
		</div>
		<div class="table_list">
			<Table :columns="columns" :data="data">
				<template slot-scope="{row}" slot="action">
					<a href="javascript:;" @click="edit(row.id)">编辑</a>
					<Divider type="vertical" />
					<a href="javascript:;" @click="get_power_tree(row.id,row.title)">菜单权限</a>
					<Divider type="vertical" />
					<a href="javascript:;" @click="get_plugin(row.id,row.title)">插件</a>
					<Divider type="vertical" />
					<a href="javascript:;" @click="del(row.id)">删除</a>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" @on-change="changePage" @on-page-size-change="changePageSize"
				:total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-total show-sizer>
			</Page>
		</div>
		<Modal v-model="modal" :title="'分配'+title+'的菜单权限'">
			<div style="height: 500px;overflow-y: auto;">
				<Tree ref="tree" :data="powerList" show-checkbox></Tree>
			</div>
			<div slot="footer">
				<Button @click="modal=false">取消</Button>
				<Button type="primary" @click="savePower">保存权限</Button>
			</div>
		</Modal>
		<Modal v-model="pModal" :title="'分配'+title+'插件'">
			<div>
				<CheckboxGroup v-model="plugin">
					<Checkbox style="margin-bottom: 8px;" border v-for="(item,index) in pluginList" :label="item.id"
						:key="index">{{item.name}}</Checkbox>
				</CheckboxGroup>
			</div>
			<div slot="footer">
				<Button @click="pModal=false">取消</Button>
				<Button type="primary" @click="savePlugin">分配插件</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: "VersionList",
		data() {
			return {
				modal: false,
				pModal: false,
				title: '',
				version_id: null,
				search: {
					type: -1,
					total: 0,
					page: 1,
					pageSize: 15
				},
				columns: [{
						title: '序号',
						type: 'index',
						width: 70
					},
					{
						title: '版本名称',
						key: 'title',
						minWidth: 120
					},
					{
						title: '版本描述',
						key: 'describe',
						minWidth: 180
					},
					{
						title: '系统类型',
						key: 'type',
						minWidth: 100,
						render: (h, params) => {
							return h('span', params.row.type ? '私有化' : 'Sass')
						}
					},
					{
						title: '现价',
						key: 'current_price',
						minWidth: 120
					},
					{
						title: '创建时间',
						key: 'create_date',
						minWidth: 180
					},
					{
						title: '操作',
						slot: 'action',
						minWidth: 180
					},
				],
				data: [],
				powerList: [],
				plugin: [],
				pluginList: []
			}
		},
		created() {
			this.init()
		},
		methods: {
			//初始化
			init() {
				var that = this
				this.requestApi('/adm/get_system_version', {
					data: this.search
				}).then(function(res) {
					if (res.status) {
						that.search.total = res.data.total
						that.data = res.data.data
					}
				})
			},
			//新增版本信息
			addData() {
				this.toUrl('/version/fromData?id=')
			},
			//编辑版信息
			edit(id) {
				this.toUrl('/version/fromData?id=' + id)
			},
			get_power_tree(id, title) {
				var that = this
				this.version_id = id
				this.title = title
				this.requestApi('/adm/get_version_power_tree', {
					id: id
				}).then(function(res) {
					that.powerList = res.data;
					that.modal = true;
				})
			},
			//保存权限设置
			savePower() {
				//获取选中及半选节点
				var data = this.$refs.tree.getCheckedAndIndeterminateNodes();
				var powerId = this.set_power(data);
				if (!powerId) return this.alertErr('请选择权限！')
				var that = this;
				this.requestApi('/adm/set_version_power', {
					data: powerId,
					id: this.version_id
				}).then(function(res) {
					if (res.status) {
						that.modal = false;
						that.alertSucc(res.msg);
					} else {
						that.alertErr(res.msg)
					}
				})
			},
			set_power(data) {
				var power = '';
				for (let i = 0; i < data.length; i++) {
					power += power == '' ? data[i].id : ',' + data[i].id;
				}
				return power;
			},
			//获取插件列表
			get_plugin(id, title) {
				var that = this
				this.version_id = id
				this.title = title
				this.requestApi('/adm/get_version_plugin', {
					id: id
				}).then(function(res) {
					that.plugin = res.data.plugin;
					that.pluginList = res.data.pluginList;
					that.pModal = true;
				})
			},
			//保存插件设置
			savePlugin() {
				if (!this.plugin.length) return this.alertErr('请至少分配一个插件！')
				var that = this;
				this.requestApi('/adm/set_version_plugin', {
					data: this.plugin,
					id: this.version_id
				}).then(function(res) {
					if (res.status) {
						that.pModal = false;
						that.alertSucc(res.msg);
					} else {
						that.alertErr(res.msg)
					}
				})
			},
			//删除版本信息
			del(id) {
				var that = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定要删除该版本吗？确定后无法更改！',
					onOk() {
						that.requestApi('/adm/del_system_version', {
							id: id
						}).then(function(res) {
							if (res.status) {
								that.alertSucc(res.msg)
								that.init()
							} else {
								that.alertErr(res.msg)
							}
						})
					}
				})
			},
			//搜索
			searchInit() {
				this.search.page = 1
				this.init()
			},
			changePage(page) {
				this.search.page = page
				this.init()
			},
			changePageSize(size) {
				this.search.page = 1
				this.search.pageSize = size
				this.init()
			}
		}
	}
</script>

<style scoped>
	.form-item {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}

	.item-label {
		width: 80px;
		text-align: right;
		vertical-align: middle;
		float: left;
		font-size: 14px;
		color: #515a6e;
		line-height: 1;
		box-sizing: border-box;
	}
</style>